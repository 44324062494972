import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { countrySchema } from './language';
import { organizationSchema } from './organization';
import { getPaginationResponseSchema } from './pagination';
import { ProjectConstructionType, ProjectInvoiceTo, ProjectSpecification, } from './project-properties';
import { projectTypeSchema } from './project-type';
export const projectSchema = z
    .object({
    id: z.number().int(),
    name: z.string(),
    organization_id: organizationSchema.innerType().shape.id,
    organization_name: z.string(),
    address: z.string().nullable(),
    country: countrySchema,
    project_type: projectTypeSchema.innerType().shape.id,
    specifications: z.array(z.nativeEnum(ProjectSpecification)),
    construction_types: z.array(z.nativeEnum(ProjectConstructionType)),
    container_name: z.string().uuid(),
    updated_at: z.string().datetime(),
    created_at: z.string().datetime(),
    billing_information: z.string().nullable(),
    billing_address: z.string().nullable(),
    org_number: z.number().int().nullable(),
    invoice_to: z.nativeEnum(ProjectInvoiceTo),
    famac_api_token: z.string().nullish(),
    active_services: z.array(z.string()),
    available_services: z.array(z.string()),
})
    .strict()
    .transform(mapToCamelCase);
export const getProjectsResponseSchema = getPaginationResponseSchema(projectSchema);
export const getProjectsRequestSchema = z
    .object({
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const createProjectResponseSchema = projectSchema;
export const createProjectRequestSchema = projectSchema
    .innerType()
    .pick({
    name: true,
    address: true,
    country: true,
    project_type: true,
    specifications: true,
    construction_types: true,
    organization_id: true,
    billing_information: true,
    billing_address: true,
    org_number: true,
    invoice_to: true,
})
    .strict()
    .transform(mapToCamelCase);
